<template>
  <section class="home-section">
    <div class="container-scroller">
      <!-- partial:partials/_navbar.html -->
      <Header title="Ajout colis" />
      <!-- partial -->
      <div class="container-fluid page-body-wrapper bg-light">
        <!-- partial:partials/_sidebar.html -->

        <!-- partial -->
        <div class="main-panel" style="width: 100%">
          <!---content wrapper start--->

  <div class="content-wrapper">
            <div class="row">
              <div class="col-md-12 grid-margin">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-end flex-wrap">
                    <div class="mt-3" >
                      <p class=" d-flex justify-content-center align-self-center " ><i class="mdi mdi-account-multiple icon-md mr-1"></i> 
                         <i class="mdi mdi-menu-right icon-sm mr-1 "></i>
                         <span class=" py-2 " >Nouveau client</span>
                       </p>
                    </div>
                                            <!--     <button @click="notification">tester notification </button> -->

                  </div>       
                </div>
              </div>
            </div>
             <div class="row" style="margin-top: 1%">
                        <div class="col-12">
                          <div
                            v-if="error"
                            class="alert alert-danger px-3 shadow-sm border border-white"
                            role="alert"
                          >
                          <i
                        class="mdi mdi-close-circle-outline pl-1"
                        style="color: darkred; font-size:20px;"
                      ></i>
                           {{error}}
                          </div>
                        </div>
                      </div>
              <div class="">
               <p 
                v-if="Chargement"
                class="text-primary justify-content-center  flex-row d-flex font-weight-bold"
              >
                 <pulse-loader
                      class="ml-2"
                      :loading="true"
                      :color="color"
                      :size="size"
                    ></pulse-loader>
              </p>
            </div>
            <div class="row">
              <form
                class="forms-sample"
                @submit.prevent="handlesubmit"
                style="width: 100%"
              >
                <div class="col-12 grid-margin stretch-card">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title">Informations :</h4>

                      <div class="row">
                        <div class="form-group col-lg-6">
                          <label for="exampleInputUsername1">Nom</label>
                          <input
                            type="text"
                            class="form-control"
                            id="exampleInputEmail2"
                            placeholder="nom"
                            v-model="t.nom"
                          />
                        </div>
                       <!--  <div class="form-group col-lg-6">
                          <label for="exampleInputUsername1">Prénom</label>
                          <input
                            type="text"
                            class="form-control"
                            id="exampleInputEmail2"
                            placeholder="prenom"
                            v-model="t.prenom"
                          />
                        </div> -->
                      </div>

                      <div class="form-group">
                        <label for="exampleInputEmail1">Adresse</label>
                        <textarea
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="2"
                          placeholder="adresse"
                          v-model="t.adresse"
                        ></textarea>
                      </div>

                      <div class="row">
                        <div class="form-group col-lg-6">
                          <label for="exampleInputUsername1">Ville</label>
                          <select
                            class="form-control form-select form-select-md"
                            aria-label="Default select example"
                            v-model="t.ville_id"
                            @change="changer"
                          >
                            <option
                              v-for="item in villes"
                              :value="item.id"
                              :key="item.id"
                            >
                              {{ item.city }}
                            </option>
                          </select>
                        </div>

                       <!--  <div class="form-group col-lg-6">
                          <label for="exampleInputUsername1">Pays</label>
                          <input
                            type="text"
                            class="form-control"
                            id="exampleInputEmail2"
                            placeholder="pays"
                            v-model="t.pays"
                          />
                        </div> -->
                      </div>

                      <div class="row">
                        <div class="form-group col-lg-6">
                          <label for="exampleInputUsername1">Mobile</label>
                          <input
                            type="text"
                            v-on:keypress="isNumber(event)"
                            class="form-control"
                            id="exampleInputEmail2"
                            autocomplete="off"
                            placeholder="ex : 06xxxxxxxx"
                            :minlength="10"
                            :maxlength="10"
                            v-model="t.mobile"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-------------------------------->
                <!--         <div class="col-12 grid-margin stretch-card" v-if="user == null">
              <div class="card">
                               <div class="card-body" >
                  <h4 class="card-title">Activation du compte</h4>
                  

                    <div class="row" style="margin-left : 4%;">

                      <div class="col-lg-6">
                                         <div class="form-check">
  <input class="form-check-input" type="checkbox" value="1" id="flexCheckDefault" v-model="t.is_actived">
  <label class="form-check-label" for="flexCheckDefault">
    Créer un compte pour ce chauffeur
  </label>
</div>
                         
                   
                  
                     
                      </div>
                   
                    </div>
                    <div class="row" v-if="t.is_actived" >
                <p style="margin-left : 5.5%; font-style: italic;color: darkred; font-weight:bolder; " class="">
                     Le login et le mot de passe seront envoyés automatiquement à cet email aprés validation
                    </p>


                    <div class="col-12">
                       <div class="form-group row" style="margin-left : 4%;">
                      <label for="exampleInputUsername2" class="col-lg-2 col-form-label" >Email : </label>
                      <div class="col-lg-6">
                            <div class="form-group">
              <input type="email" class="form-control" id="exampleInputEmail2" placeholder="email" v-model="t.email" >
              

                      </div>

                      </div>
                    </div>
  
                      </div>
                     
                  </div>

   

                         <div class="row " style="margin-top : 1%;">
                       <div class="col-12"> 
                               <div  v-if="error" class="alert alert-danger text-center" role="alert">
                       {{error}}
                </div>
                       </div>
                     </div>
           
              
                </div>
                
              </div>
            </div> -->

                <!---------------------------->
                <!-- 
             <div class="col-12 grid-margin stretch-card" v-if="t.is_actived == 1 && user!=null &&user.is_archived ==0">
              <div class="card">
                               <div class="card-body" >
                  <h4 class="card-title">Désactivation du compte</h4>
                  

                    <div class="row" style="margin-left : 4%;">

                      <div class="col-lg-6">
                                         <div class="form-check">
  <input class="form-check-input" type="checkbox" value="1" id="flexCheckDefault" v-model="t.user_archived">
  <label class="form-check-label" for="flexCheckDefault">
    Désactiver le compte de ce chauffeur
  </label>
</div>
                         
                   
                  
                     
                      </div>
                   
                    </div>
                    <div class="row" >
                <p style="margin-left : 5.5%; font-style: italic;color: darkred; font-weight:bolder; " class="">
                     Une fois désactivé, le vendeur ne pourra plus accéder à l'ensemble de ses activités sur son compte.
                    </p>


                    <div class="col-12">
                       <div class="form-group row" style="margin-left : 4%;">
                      <label for="exampleInputUsername2" class="col-lg-2 col-form-label" >Email : </label>
                      <div class="col-lg-6">
                            <div class="form-group">
              <input type="email" disabled class="form-control" id="exampleInputEmail2" placeholder="email" v-model="user.email" >
              

                      </div>

                      </div>
                    </div>
  
                      </div>
                     
                  </div>

   

          
              
                </div>
                
              </div>
            </div> -->
                <!------------------------------->
                <!--            <div class="col-12 grid-margin stretch-card" v-if="user!=null && user.is_archived == 1">
              <div class="card">
                               <div class="card-body" >
                  <h4 class="card-title">Information du compte</h4>
                  

                    <div class="row" style="margin-left : 4%;">

                      <div class="col-lg-6">
                                         <div class="form-check">
  <input class="form-check-input" type="checkbox" value="1" id="flexCheckDefault" checked disabled>
  <label class="form-check-label" for="flexCheckDefault">
    Désactivé
  </label>
</div>
                         
                   
                  
                     
                      </div>
                   
                    </div>
                    <div class="row" >
                

                    <div class="col-12">
                  
  
                      </div>
                     
                  </div>

   

              
                </div>
                
              </div>
            </div> -->
                <div class="row">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3">
                    <button
                      @click="showMessage()"
                      class="btn btn-success mr-2"
                      style="width: 100%"
                       v-if="!isLoading">Valider   
                    </button>
                     <div class="text-center py-1" v-else>
                            <pulse-loader
                      class="ml-2"
                      :loading="true"
                      :color="color1"
                      :size="size1"
                    ></pulse-loader>
                          </div>
                  </div>
                  <div class="col-lg-3">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      style="width: 100%"
                      @click="$router.go(-1)"
                    >
                      Retour
                    </button>
                  </div>
                </div>
              </form>

              <!----------------------------------------->
            </div>
          </div>

          <!-- content-wrapper ends -->
          <!-- partial:partials/_footer.html -->
          <Footer />
          <!-- partial -->
        </div>
        <!-- main-panel ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
  </section>
</template>

<script>
//import axios from "axios"
import Swal from "sweetalert2";
// import Menu from '@/components/Menu.vue';
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
 import PulseLoader from "vue-spinner/src/PulseLoader.vue";

//import Loading from 'vue3-loading-overlay';

export default {
  props: {
    idt: Number,
  },

  components: {
    // Menu,
    Header,
    Footer,
    PulseLoader,
    // Loading
  },
  data() {
    return {
       color1:'#5cb85c',
      size1:'10px',
      color:'#0275d8',
      size:'5px',
      test: "oui",
      // idt : 8,
      t: {},
      user: {},
      error: "",
      isLoading: false,
      villes: [],
      Chargement : true,
    };
  },
  created() {
    this.$store
      .dispatch("getItem", {
        //data to be sended
        nameRoute: "/api/getallcities",
      })
      .then((res) => {
        this.Chargement = false;
        console.log(res.data);
        this.villes = res.data;
      })
      .catch((err) => {
        this.Chargement = false;
        console.log(err);
        if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur"
         else
        this.error = "Une erreur s'est produite lors du chargement des villes! ";
      });
  },
  mounted() {
    console.log("mounted");
    this.t = JSON.parse(this.$route.params.data);
    this.user = this.t.user;
    console.log(this.t);
  },

  methods: {
    edit() {
      this.isLoading = true;
      this.$store
        .dispatch("postItem", {
          //data to be sended
          nameRoute: "/api/editC/" + this.t.id,
          t: this.t,
        })
        .then((res) => {
          this.isLoading = false;
          this.notification("Client modifié avec succés")
          console.log(res.data);
          this.$router.push("/client");
        })
        .catch((err) => {
          this.isLoading=false;
          this.notificationError("Un problème est survenu lors de la mise à jour du client")
          if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur"
         else
        this.error = "Une erreur s'est produite lors de la modification de ce client ! ";
        });
    },
     notification(msg){
      this.$notify({
  group : "foo",
  type: 'success',
  title: 'DONE',
  text: msg
});
    },
    notificationError(msg){
      this.$notify({
  group : "foo",
  type: 'error',
  title: 'Error',
  text: msg
});
    },

    showMessage() {
      Swal.fire({
        title: "Attention!",
        text: "Etes vous sur de vouloir modifier ce client?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "green",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
          this.edit();
        }
      });
    },
  },
};
</script>

<style scoped>
.home-section {
  position: relative;
  background: #e4e9f7;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}
.sidebar.open ~ .home-section {
  left: 250px;
  width: calc(100% - 250px);
}
.home-section .text {
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 0px;
}
i {
  color: #6c757d;
  font-size: 30px;
}
</style>
