import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import Notifications from 'vue-notification'

Vue.config.productionTip = false;
Vue.use(Notifications)

new Vue({
  router,
  store,
 // Notifications,
  render: (h) => h(App),
}).$mount("#app");
