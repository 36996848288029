

<template>
              

      <section class="home-section" >
         
         <div class="container-scroller" >
    <!-- partial:partials/_navbar.html -->
    <Header title="Ajout colis" />
    <!-- partial -->
    <div class="container-fluid page-body-wrapper bg-light"  >
      <!-- partial:partials/_sidebar.html -->
   
    
      <!-- partial -->
      <div class="main-panel" style="width : 100%;">
        <!---content wrapper start--->
              
        <div class="content-wrapper">

                  <div class="row" >
            <div class="col-md-12 grid-margin">
              <div class="d-flex justify-content-between flex-wrap row">
                <div class="d-flex align-items-end flex-wrap col-lg-8">
                
               
                <div class="mt-3">
                     <p><i class="mdi mdi-settings icon-md mr-1" style="color: #6c757d; font-size: 30px;"></i>  </p>
                    </div>
      


                </div>
                <div class="col-lg-2 d-flex justify-content-between align-items-end flex-wrap">
              <!--       <div class="input-group w-100 p-0 bg-light" style=" border-bottom: 1px solid black; ">
            
              <input type="date" class="form-control bg-light " v-model="mydate"  aria-label="search" aria-describedby="search" style="height : 40px; border : none; box-shadow: none !important;" @change="show">
            </div> -->
                   

                  

                 
<!-- <button @click="t">tester</button> -->
                  
                  
                </div>
              </div>
            </div>
          </div>
          
       <!--------------->
     
 
       <!--------------->
          <div class="row">
            <div class=" stretch-card col-lg-12 m-1 p-0">
              <div class="card p-0">
                  <div class="card-header text-left text-secondary bg-light py-3 px-4" >
                     <b> Confidentialité </b>

                 </div>
          
                <div class="card-body bg-light p-0">
                   
                    
                 
                 
                                                     
                   
                                   
 
  <div class="row" style="max-width : 95%; margin-left : 2%;">
    <div class="d-flex bd-highlight my-4">
  <div class="p-2 flex-fill bd-highlight fw-bold">Adresse mail</div>
  <div class="p-2 flex-fill bd-highlight">{{user.email}}</div>
  <div class="p-2  bd-highlight"><a  @click="openModel('mail')" href="#" class="link-primary">Changer</a></div>
</div>
 <div class="d-flex bd-highlight my-4">
  <div class="p-2 flex-fill bd-highlight fw-bold">Mot de passe</div>
  <div class="p-2 flex-fill bd-highlight"> ******** </div>
  <div class="p-2  bd-highlight"><a href="#" @click="openModel('pass')" class="link-primary">Changer</a></div>
</div>
<div style="white-space: pre-line"  v-if="error4" class="alert alert-danger mx-4 mt-0 text-center row justify-content-center" role="alert">
                 {{ error4}}
                </div>
<div class="row d-flex justify-content-center" >
                            <div class="text-center">
                            <pulse-loader
                           
                      class="mx-5"
                      v-if="done"
                      :loading="true"
                      :color="color1"
                      :size="size1"
                    ></pulse-loader>
                        </div>

</div>
 
    
 
 
     </div>
     <hr>
    <div class="row m-5" style="max-width : 95%; margin-left : 2%;">
      <p class="text-secondary fw-bold">Pour raison de sécurité, Le mot de passe doit respecter des exigences de complexité, Il doit contenir</p>
      <ul class="check-list">
      <li><p class="text-dark">8 caractères au minimum</p></li>
      <li><p class="text-dark">Au moins un caractère majuscule</p></li>
      <li><p class="text-dark">Au moins un caractère minuscule</p></li>
      <li><p class="text-dark">Au moins un caractère numérique</p></li>
      <li><p class="text-dark">Au moins un caractère non alphanumérique</p></li>
       </ul>
    </div>
   <!--  <div class="row mt-4" style="max-width : 95%; margin-left : 2%;">
      <p class="text-secondary fw-bold">Nos recommandations pour des mots de passe sécurisés</p>
      <ul class="check-list">
      <li><p class="text-dark">Il ne doit pas contenir des données personnelles </p></li>
      <li><p class="text-dark">II ne doit pas </p></li>
      <li><p class="text-dark">Au moins un caractère minuscule</p></li>
      <li><p class="text-dark">Au moins un caractère numérique</p></li>
      <li><p class="text-dark">Au moins un caractère spéciale</p></li>
       </ul>
    </div> -->
      
     

     <div v-if="myModel" class="bg-light">
    <transition name="model">
     <div class="modal-mask">
      <div class="modal-wrapper">
       <div class="modal-dialog  modal-dialog-centered modal-lg">
        <div class="modal-content bg-light" v-if="show_mail">
         <div class="modal-header">
                     <h4 class="modal-title text-center text-primary">Changement de l'adresse mail</h4>

          <button type="button"  @click="close()"><span aria-hidden="true">&times;</span></button>
         </div>
         <div class="modal-body">
             <div class="d-flex flex-row w-100 mb-0  m-4 p-4 ">
        <form @submit.prevent="handlesubmit('mail')" class="w-100 " >
  <div class="form-group row">
    <label for="staticEmail" class="col-lg-5 col-form-label">Adresse mail actuelle</label>
    <div class="col-lg-6">
    <input type="email" required  id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter current email" v-model="old_mail" v-bind:class="[error1 ?  'input_error' :'', 'none', 'form-control']">
    </div>
  </div>
  <div class="form-group row">
    <label for="staticEmail" class="col-lg-5 col-form-label">Nouvelle adresse email</label>
    <div class="col-lg-6">
    <input type="email" required class="form-control" id="exampleInputEmail2" aria-describedby="emailHelp" placeholder="Enter new email" v-model="new_mail" v-bind:class="[error2 ?  'error' :'', 'none']">
    </div>
  </div>
  <div class="form-group row">
    <label for="staticEmail"  class="col-lg-5 col-form-label">Confirmation nouvelle adresse mail</label>
    <div class="col-lg-6">
    <input type="email" required class="form-control" id="exampleInputEmail3" aria-describedby="emailHelp" placeholder="Enter new email confirmation" v-model="new_mail_confirmation" v-bind:class="[error2 ?  'error' :'', 'none']">
    </div>
  </div>
  <div class="d-flex justify-content-center">
           <button type="submit" class="btn btn-success mr-2 mt-4 mb-O" style="width : 25%;" >
              Valider
           </button>

  </div>
</form>

       
         </div>
         
    <div style="white-space: pre-line"  v-if="error1" class="alert alert-danger mx-4 mt-0 text-center" role="alert">
                 {{ error1 }}
                </div>
    <div style="white-space: pre-line"  v-if="error2" class="alert alert-danger mx-4 mt-0 text-center" role="alert">
                 {{ error2 }}
                </div>
    <div style="white-space: pre-line"  v-if="error3" class="alert alert-danger mx-4 mt-0 text-center" role="alert">
                 {{ error3 }}
                </div>

         

          
         </div>
        </div>


        <!------------------------>

             <div class="modal-content bg-light" v-if="show_pass">
         <div class="modal-header">
                     <h4 class="modal-title text-center text-primary">Changement du mot de passe</h4>

          <button type="button"  @click="close()"><span aria-hidden="true">&times;</span></button>
         </div>
         <div class="modal-body">
             <div class="d-flex flex-row w-100 mb-0  m-4 p-4 ">
        <form @submit.prevent="handlesubmit('pass')" class="w-100 " >
  <div class="form-group row">
    <label for="staticPass" class="col-lg-5 col-form-label">Mot de passe actuel</label>
    <div class="col-lg-6">
    <input type="password" required class="form-control" id="exampleInputPass1" aria-describedby="emailHelp" placeholder="Enter current password" v-model="t.old_pass" >
    </div>
  </div>
  <div class="form-group row">
    <label for="staticPass" class="col-lg-5 col-form-label">Nouveau mot de passe</label>
    <div class="col-lg-6">
    <input type="password"  required class="form-control" id="exampleInputPass2" aria-describedby="emailHelp" placeholder="Enter new password" v-model="new_pass">
    </div>
  </div>
  <div class="form-group row">
    <label for="staticPass"  class="col-lg-5 col-form-label">Confirmation du nouveau mot de passe</label>
    <div class="col-lg-6">
    <input type="password" required class="form-control" id="exampleInputPass3" aria-describedby="emailHelp" placeholder="New password confirmation" v-model="new_pass_confirm">
    </div>
  </div>
  <div class="d-flex justify-content-center">
            <button type="submit" class="btn btn-success d-flex px-4 mr-2 mt-4 mb-O" :disabled="isLoading" >
              <span v-if="!isLoading">Valider</span>
              <span v-else>Verification en cours ...</span>
             
           </button>

  </div>
</form>

       
         </div>
         <div style="white-space: pre-line"  v-if="error" class="alert alert-danger mx-4 mt-0 text-center" role="alert">
                 {{ error}}
                </div>
          <!-- <div  v-if="error" class="alert alert-light text-danger text-center m-2" role="alert">
                        <ul id="example-1" v-for="(item, index) in errors" :key="index">
                                         <li > {{ item }}</li>
                       </ul> 
          </div> -->
         
  
   
         

          
         </div>
        </div>
        
       </div>
      </div>
     </div>
    </transition>
   </div> 


                </div>
              
                        
              </div>
            </div>
          </div>
        
        </div>

        <!-- content-wrapper ends -->
        <!-- partial:partials/_footer.html -->
      <Footer />
        <!-- partial -->
      </div>
      <!-- main-panel ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
        
      </section>

</template>

<script>
// import Menu from '@/components/Menu.vue'; 
import Header from '@/components/header.vue'; 
import Footer from '@/components/footer.vue'; 
import Swal from 'sweetalert2';
import moment from 'moment';
 import PulseLoader from "vue-spinner/src/PulseLoader.vue";










export default {

  components : {
    // Menu,
    Header,
    Footer,
    PulseLoader,
    //detailsCh,

},
  data()  {
    return {
   count : '',
   color1:'#0275d8',
   size1:'5px',
   op : {},
   t:{},
   done:false,
   mydate:'',
   isLoading:false,
   myModel:false,
   old_mail:'',
   error1:'',
   error2:'',
   error3:'',
   error4:'',
   new_mail:'',
   new_mail_confirmation:'',
   new_pass : '',
   new_pass_confirm : '',
   showButton : false,
   show_mail : false,
   show_pass : false,
   regex : null,
   error_pass : null,
   erros:[],
   activity:{},
 

   
    }
  },

    mounted(){
     // this.showActivities(this.page);
      this.regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;
    

  },
 
    computed: {
        user(){
        return this.$store.getters.get_user;
              },
        link(){
        return this.$store.getters.get_link;
              },

      
    },

    

 methods: {
   
    openModel(action){
  // this.first_name = '';
  // application.last_name = '';
  console.log("clicked!!!")
    if(action == 'mail') {this.show_pass=false; this.show_mail = true;}
    if(action == 'pass') {this.show_mail=false;this.show_pass = true;}
     this.myModel = true;
     this.reset();
  },
     handlesubmit(action){

       if(action == 'mail')
       {
         console.log("test")
       if(this.old_mail != this.user.email) this.error1 = "L'adresse mail que vous avez entré ne correspond pas à votre compte";
       else if(this.new_mail == this.user.email) this.error3 = "La nouvelle adresse mail que vous avez entré est identique à votre adresse actuelle";
       else if(this.new_mail != this.new_mail_confirmation) this.error1 = "Les deux adresses mail ne correspondent pas";
       else {
         console.log("yes");
         this.myModel = false;
         this.showMessage();
       }}

       if(action == 'pass')
       {
         this.isLoading = true;
         this.error = "";
         console.log("here validation", this.isLoading)
         this.changepassword();
         /* const exigene =  new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/)
         console.log(exigene.test(this.t.new_pass));
       if (!exigene.test(this.t.new_pass ))  this.error_pass = "Le mot de passe doit contenir au minimum 8 caracteres , il doit contenir au moin un caractère majuscule, un caractére minuscule, un caractère numérique et un caractère non alphanumérique";
       else if(this.t.new_pass != this.t.new_pass_confirm) this.error_pass = "Les deux mots de passe ne correspondent pas";
       else {
         console.log("yes")
         this.myModel = false;
         this.showMessagePass();
       } */
       }

     },

   format_date(value){
         if (value) {
           return moment(String(value)).format('D MMMM YYYY')
          }
      },
      show(){
       this.t.date = this.mydate;
       this.showActivities(this.page);
      },  

      close(){
        console.log("test")
        this.t.old_pass = '';
        this.new_pass = '';
        this.new_pass_confirm = '';
        this.old_mail = '';
        this.new_mail = '';
        this.new_mail_confirmation = '';
        this.myModel=false;
        this.show_mail = false;
        this.show_pass = false;
        this.error = '';
        this.error1 = '';
        this.error2 = '';
        this.error3 = '';
        this.error4 = '';
      },
      reset(){
        console.log("test")
        this.t.old_pass = '';
        this.new_pass = '';
        this.new_pass_confirm = '';
        this.old_mail = '';
        this.new_mail = '';
        this.new_mail_confirmation = '';
        this.error = '';
        this.error1 = '';
        this.error2 = '';
        this.error3 = '';
        this.error4 = '';
      },


 

  

    showMessage(){
      this.close;
      Swal.fire({
      title: 'Attention!',
      text: "Etes vous sur de vouloir changer votre adresse mail?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#d33',
      cancelButtonText: "Annuler",
      confirmButtonText: 'Ok'
      })
      .then((result) => {
       if (result.isConfirmed) {
         this.changemail();
     
  }
})
    },
     showMessagePass(){
       this.close();
      Swal.fire({
      title: 'Attention!',
      text: "Etes vous sur de vouloir changer votre mot de passe?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#d33',
      cancelButtonText: "Annuler",
      confirmButtonText: 'Ok'
      })
      .then((result) => {
       if (result.isConfirmed) {
         this.changepassword();
     
  }
})
    },
    confirmed(text){
      Swal.fire({
    icon: 'success',
    title: text+"Modifié",
    showConfirmButton: false,
    timer: 1500
    })
    .then(()=>{
      console.log("yes")
      this.logout();
    })
    },
    logout(){
      /* localStorage.removeItem('token')
      localStorage.removeItem('userID') */
      this.$store.dispatch('performLogoutAction')
      .then( res => {
        console.log(res)
      this.$router.push('/')

      })
      .catch( err => {
        console.log(err)
      })
    },
    checkpass(){
      this.$store.dispatch('postItem', {//data to be sended
      nameRoute : '/api/checkpass/'+this.user.id,
      t : this.t
      })    
     .then(res => {
      console.log("checkpass",res.data);
     })
     .catch(err => {
       //if(error.response.data.errors.email)
      this.error_pass = err.response.data.error;
         
     })

    },
    changemail(){
      this.done=true;
      this.error = "";
      this.t.email = this.new_mail;
      this.$store.dispatch('postItem', {//data to be sended
      nameRoute : '/api/changemail/'+this.user.id,
      t : this.t
      })    
     .then(res => {
       this.done=false;
       this.addActivity("Change mail",this.link+'/api/changemail/'+this.user.id )
       console.log(res.data);
       this.confirmed("email");
     })
     .catch(error => {
       this.done=false;
       console.log(error)
       if(error.response.data.errors.email)
      this.error4 = error.response.data.errors.email[0];
         
     })

    },
    addActivity(action, link){
      console.log("add activity")
      this.activity.user_id= this.user.id;
      this.activity.action= action;
      this.activity.model = "User"
      this.activity.url=this.$store.getters.get_link+link;
      this.activity.user_agent=navigator.appName+" "+navigator.appVersion ;
      this.activity.item_id = this.user.id;
      console.log("activity..........", this.activity)
        this.$store.dispatch('postItem', {//data to be sended
          nameRoute : '/api/addactivity',
          t : this.activity
          })    
        .then(res => {
          console.log(res.data);
        })
        .catch(error => {
            console.log(error)
           
        }) 

      },
    changepassword(){
      this.t.new_passwoard_confirmation = this.new_pass_confirm;
      this.t.new_passwoard = this.new_pass;
      this.$store.dispatch('postItem', {//data to be sended
      nameRoute : '/api/changepassword/'+this.user.id,
      t : this.t
      })    
     .then(res => {
        this.isLoading = false;
        console.log(res.data)
        this.addActivity("Change password",this.link+'/api/changepassword/'+this.user.id )
        console.log(res.data);
        this.confirmed("mot  de passe");
      //this.logout();
     })
     .catch(err => {
       this.isLoading = false;
      // console.log(err.response.data)
       if(JSON.stringify(err.response.status) == 401) this.error = err.response.data.error;
       else if(JSON.stringify(err.response.status) == 422)  {
                                                             if(err.response.data.errors.new_passwoard) this.error = err.response.data.errors.new_passwoard[0]
                                                             else if(err.response.data.errors.new_passwoard_confirmation) this.error = err.response.data.errors.new_passwoard_confirmation[0]
                                                              // console.log(err.response.data.errors.new_pass_confirm)

                                                             } 
       else { this.error = "An error has occured while changing password, Please try again"}
       console.log(this.error)
         
     })

    },

  }
}
</script>
<style scoped>
tr {
   line-height: 25px;
   min-height: 25px;
   height: 25px;
}


input[type=search]:focus{
  outline: inherit;
  box-shadow: inherit;
  background: rgb(216, 215, 208) !important;

}
.check-list {
  margin: 0;
  padding-left: 1.2rem;
}

.check-list li {
  position: relative;
  list-style-type: none;
  padding-left: 2.5rem;
  margin-bottom: 0.5rem;
}

.check-list li:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: -2px;
    width: 5px;
    height: 11px;
    border-width: 0 2px 2px 0;
    border-style: solid;
    border-color: #00a8a8;
    transform-origin: bottom left;
    transform: rotate(45deg);
}
.home-section{
  position: relative;
  background: #E4E9F7;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}
.sidebar.open ~ .home-section{
  left: 250px;
  width: calc(100% - 250px);
}
.home-section .text{
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 0px;
}
/* serachbar */

.search-box{
  width: fit-content;
  height: fit-content;
  position: relative;
}
.input-search{
  height: 50px;
  width: 50px;
  border-style: none;
  padding: 10px;
  font-size: 18px;
  outline: none;
  border-radius: 25px;
  transition: all .5s ease-in-out;
  background-color: transparent;
  padding-right: 40px;
  color:black;
}
.input-search::placeholder{
  color: #6C757D;
  font-size: 18px;
  font-weight: 100;
  font-style: italic;
}
.none{
  box-shadow: none !important;
}
.error{
  -webkit-box-shadow: 0px 0px 2px 1px red !important;
    box-shadow: 0px 0px 2px 1px red !important;

}

.btn-search{
  width: 50px;
  height: 50px;
  border-style: none;
  font-size: 25px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  color:white ;
  pointer-events: painted;  
}
.btn-search ~ .input-search{
  width: 80%;
  border-radius: 0px;
  background-color: transparent;
  border-bottom:1px solid #6C757D;
  transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}
.input-search:focus{
  width: 80%;
  border-radius: 0px;
  background-color: transparent;
   box-shadow: 0  0.2rem #6C757D;
  transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}
.modal-mask {
     position: fixed;
     z-index: 9998;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: rgba(0, 0, 0, .5);
     display: table;
     transition: opacity .3s ease;
   }

   .modal-wrapper {
     display: table-cell;
     vertical-align: middle;
   }


</style>
import { Script } from "vm";