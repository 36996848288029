<template>
  <section class="home-section ">
    <div class="container-scroller">
      <!-- partial:partials/_navbar.html -->
      <Header title="Ajout colis" />
      <!-- partial -->
      <div class="container-fluid page-body-wrapper bg-light" >
        <!-- partial:partials/_sidebar.html -->

        <!-- partial -->
        <div class="main-panel " style="width: 100%">
          <!---content wrapper start--->

          <div class="content-wrapper p-2 w-100 h-100">
            <div class="row" >
              <div class="col-md-12 grid-margin m-1">
                <div class="d-flex mt-4 pr-4">
  <div class="mr-auto p-2"> <div class="mt-3">
                      <p><i class="mdi mdi-home icon-md" style="color: #6c757d; font-size: 30px;"></i></p>
                    </div>
                    </div>
  <div class="p-2">                   
       <date-picker v-model="time1" type="month" @change="test" valueType="format" ></date-picker>
</div>
</div>
               
              </div>
            </div>

            <div class="">
               <p 
                v-if="chargement"
                class="text-primary justify-content-center  flex-row d-flex font-weight-bold"
              >
                Chargement en cours <pulse-loader
                      class="ml-2"
                      :loading="true"
                      :color="color"
                      :size="size"
                    ></pulse-loader>
              </p>
            </div>

            <!----->

            <!--------------->
            <div class="row m-0 p-0" v-show="select == 1">
              <form class="forms-sample d-flex justify-content-end p-0 m-0">
                <div class="form-group p-0 mx-1 col-lg-2">
                  <!--                   <label for="input_from" class="p-0 m-0 text-primary font-weight-bold" > From </label>
 -->
                  <input
                    type="text"
                    onfocus="(this.type='date')"
                    class="form-control d-inline bg-white"
                    id="input_from"
                    placeholder="Start date "
                    v-model="from"
                    @change="changerDate"
                  />
                </div>
                <div class="form-group p-0 mx-1 col-lg-2">
                  <!--                   <label for="input_to" class="p-0 m-0 text-primary font-weight-bold" > To </label>
 -->
                  <input
                    type="text"
                    onfocus="  (this.type='date')"
                    class="form-control d-inline bg-white"
                    id="input_to"
                    placeholder="End date"
                    v-model="to"
                    @change="changerDate"
                  />
                </div>
              </form>
            </div>
            <div class="row justify-content-center w-100 px-3">
              <p
                class="alert w-100 text-center alert-danger shadow-sm border border-white fw-bold "
                v-show="error"
              >
                {{ error }}
              </p>
            </div>

            <!--------------->

            <div class="row">
              <div class="stretch-card col-lg-12 p-0">
                <div class="card bg-transparent border border-0">
                  <div class="card-body p-0">
                    <div class="d-flex justify-content-end py-2"></div>

                    <div class="row mt-2 w-100">
                      <div class="col-lg-12">
                        <div class="row px-3 h-100">
                          <div class="col-lg-3 mb-1">
                            <div class="card l-bg-cherry h-100">
                              <a
                                class="card-statistic-3 p-4 data-card h-100"
                                
                                
                              >
                                <!-- <div class="card-icon card-icon-large">
                      <i class="mdi mdi-check-circle"></i></div> -->
                                <div class="mb-0 h-50">
                                  <h5 class="card-title mb-0">
                                    <i
                                      class="mdi mdi-check-circle text-success"
                                    ></i>

                                    Livré
                                  </h5>
                                  <p class="h-75">Tous les colis livrés</p>
                                </div>
                                <div class="row align-items-center mb-2 d-flex">
                                  <div class="col-8">
                                    <h2
                                      class="d-flex align-items-center mb-0 text-success"
                                    >
                                      {{ total.livre ? total.livre : 0 }}
                                    </h2>
                                  </div>
                                  <div class="col-4 text-right text-dark">
                                    <span
                                      >{{
                                        total.livre
                                          ? percentage(total.livre, sum)
                                          : 0
                                      }}%
                                      <i class="fa fa-arrow-up text-success"></i
                                    ></span>
                                  </div>
                                </div>
                                <div
                                  class="progress mt-1"
                                  data-height="8"
                                  style="height: 8px"
                                >
                                  <div
                                    class="progress-bar bg-success"
                                    role="progressbar"
                                    v-bind:style="{
                                      width: percentage(total.livre, sum) + '%',
                                    }"
                                    aria-valuenow="100"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div class="col-lg-3 mb-1">
                            <div class="card l-bg-cherry h-100">
                              <a
                                class="card-statistic-3 p-4 data-card h-100"
                                
                              >
                                <!-- <div class="card-icon card-icon-large">
                      <i class="mdi mdi-check-circle"></i></div> -->
                                <div class="mb-0 h-50">
                                  <h5 class="card-title mb-0">
                                    <i
                                      class="mdi mdi-cart"
                                      style="color: #ff007f"
                                    ></i>
                                    En préparation
                                  </h5>
                                  <p class="h-50">
                                    Colis en cours de préparation
                                  </p>
                                </div>
                                <div class="row align-items-center mb-2 d-flex">
                                  <div class="col-8">
                                    <h2
                                      class="d-flex align-items-center mb-0"
                                      style="color: #ff007f"
                                    >
                                      {{
                                        count.preparation
                                          ? count.preparation
                                          : 0
                                      }}
                                    </h2>
                                  </div>
                                  <div class="col-4 text-right text-dark">
                                    <span
                                      >{{
                                        count.preparation
                                          ? percentage(count.preparation, sum)
                                          : 0
                                      }}%
                                      <i
                                        class="fa fa-arrow-up"
                                        style="color: #ff007f"
                                      ></i
                                    ></span>
                                  </div>
                                </div>
                                <div
                                  class="progress mt-1"
                                  data-height="8"
                                  style="height: 8px"
                                >
                                  <div
                                    class="progress-bar"
                                    role="progressbar"
                                    style="background: #ff007f"
                                    v-bind:style="{
                                      width:
                                        percentage(count.preparation, sum) +
                                        '%',
                                    }"
                                    aria-valuenow="100"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div class="col-lg-3 mb-1">
                            <div class="card l-bg-cherry h-100">
                              <a
                                class="card-statistic-3 p-4 data-card h-100"
                               
                              >
                                <!-- <div class="card-icon card-icon-large">
                      <i class="mdi mdi-check-circle"></i></div> -->
                                <div class="mb-0 h-50">
                                  <h5 class="card-title mb-0">
                                    <i
                                      class="mdi mdi-home-variant text-primary"
                                    ></i>
                                    En attente d'enlèvement
                                  </h5>
                                  <p>
                                    Les colis prêts à être enlevés par Jiblili
                                  </p>
                                </div>
                                <div class="row align-items-center mb-2 d-flex">
                                  <div class="col-8">
                                    <h2
                                      class="d-flex align-items-center mb-0 text-primary"
                                    >
                                      {{ count.enlv ? count.enlv : 0 }}
                                    </h2>
                                  </div>
                                  <div class="col-4 text-right text-dark">
                                    <span
                                      >{{
                                        count.enlv
                                          ? percentage(count.enlv, sum)
                                          : 0
                                      }}%
                                      <i class="fa fa-arrow-up text-primary"></i
                                    ></span>
                                  </div>
                                </div>
                                <div
                                  class="progress mt-1"
                                  data-height="8"
                                  style="height: 8px"
                                >
                                  <div
                                    class="progress-bar bg-primary"
                                    role="progressbar"
                                    style="width: 25%; background: #ffde00"
                                    v-bind:style="{
                                      width: percentage(count.enlv, sum) + '%',
                                    }"
                                    aria-valuenow="100"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div class="col-lg-3 mb-1">
                            <div class="card l-bg-cherry h-100">
                              <a
                                class="card-statistic-3 p-4 data-card h-100"
                                
                              >
                                <!-- <div class="card-icon card-icon-large">
                      <i class="mdi mdi-check-circle"></i></div> -->
                                <div class="mb-0 h-50">
                                  <h5 class="card-title mb-0">
                                    <i
                                      class="mdi mdi-close-circle text-danger"
                                    ></i>
                                    Retourné
                                  </h5>
                                  <p>Les colis récupérés par l'éxpéditeur</p>
                                </div>
                                <div class="row align-items-center mb-2 d-flex">
                                  <div class="col-8">
                                    <h2
                                      class="d-flex align-items-center mb-0 text-danger"
                                    >
                                      {{ total.retourne ? total.retourne : 0 }}
                                    </h2>
                                  </div>
                                  <div class="col-4 text-right">
                                    <span
                                      >{{
                                        total.retourne
                                          ? percentage(total.retourne, sum)
                                          : 0
                                      }}
                                      %
                                      <i class="fa fa-arrow-up text-danger"></i
                                    ></span>
                                  </div>
                                </div>
                                <div
                                  class="progress mt-1"
                                  data-height="8"
                                  style="height: 8px"
                                >
                                  <div
                                    class="progress-bar bg-danger"
                                    role="progressbar"
                                    v-bind:style="{
                                      width:
                                        percentage(total.retourne, sum) + '%',
                                    }"
                                    aria-valuenow="100"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

              <!--       <div class="row mt-2">
                      <div class="col-lg-12">
                        <div class="row px-3">
                          <div class="col-lg-4 mb-1">
                            <div class="card l-bg-cherry h-100">
                              <a
                                class="card-statistic-3 p-4 data-card h-100"
                                @click="changerDash(arr5, 'tous')"
                              >
                               
                                <div class="mb-0 h-50">
                                  <h5 class="card-title mb-0">
                                    <i
                                      class="mdi mdi-package-variant text-secondary"
                                    ></i>
                                    Tous les colis
                                  </h5>
                                  <p>la totalité des colis</p>
                                </div>
                                <div class="row align-items-center mb-2 d-flex">
                                  <div class="col-8">
                                    <h2
                                      class="d-flex align-items-center mb-0 text-secondary"
                                    >
                                      {{ sum }}
                                    </h2>
                                  </div>
                                  <div class="col-4 text-right">
                                    <span
                                      >100%
                                      <i
                                        class="fa fa-arrow-up text-secondary"
                                      ></i
                                    ></span>
                                  </div>
                                </div>
                                <div
                                  class="progress mt-1"
                                  data-height="8"
                                  style="height: 8px"
                                >
                                  <div
                                    class="progress-bar bg-secondary"
                                    role="progressbar"
                                    style="width: 100%"
                                    aria-valuenow="100"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div class="col-lg-4 mb-1">
                            <div class="card l-bg-cherry h-100">
                              <a
                                class="card-statistic-3 p-4 data-card h-100"
                                @click="changerDash(arr6, 'encours')"
                              >
                               
                                <div class="mb-0 h-50">
                                  <h5 class="card-title mb-0">
                                    <i
                                      class="mdi mdi-transfer text-warning"
                                    ></i>

                                    Chez Jiblili
                                  </h5>
                                  <p>
                                    Les colis pris en charge par Jiblili Express
                                  </p>
                                </div>
                                <div class="row align-items-center mb-2 d-flex">
                                  <div class="col-8">
                                    <h2
                                      class="d-flex align-items-center mb-0 text-warning"
                                    >
                                      {{ total.encours ? total.encours : 0 }}
                                    </h2>
                                  </div>
                                  <div class="col-4 text-right">
                                    <span>
                                      {{
                                        total.encours
                                          ? percentage(total.encours, sum)
                                          : 0
                                      }}%
                                      <i class="fa fa-arrow-up text-warning"></i
                                    ></span>
                                  </div>
                                </div>
                                <div
                                  class="progress mt-1"
                                  data-height="8"
                                  style="height: 8px"
                                >
                                  <div
                                    class="progress-bar bg-warning"
                                    role="progressbar"
                                    style="width: 25%; background: #ffde00"
                                    v-bind:style="{
                                      width:
                                        percentage(total.encours, sum) + '%',
                                    }"
                                    aria-valuenow="100"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div class="col-lg-4 mb-1">
                            <div class="card l-bg-cherry h-100">
                              <a
                                class="card-statistic-3 p-4 data-card h-100"
                                href="#"
                                @click="changerDash(arr3, 'relais')"
                              >
                          
                                <div class="mb-0 h-50">
                                  <h5 class="card-title mb-0">
                                    <i
                                      class="mdi mdi-map-marker"
                                      style="color: #8031a7"
                                    ></i>
                                    Au point relais
                                  </h5>
                                  <p>Les colis disponibles au point relais</p>
                                </div>
                                <div class="row align-items-center mb-2 d-flex">
                                  <div class="col-8">
                                    <h2
                                      class="d-flex align-items-center mb-0"
                                      style="color: #8031a7"
                                    >
                                      {{ total.relay ? total.relay : 0 }}
                                    </h2>
                                  </div>
                                  <div class="col-4 text-right text-dark">
                                    <span
                                      >{{
                                        total.relay
                                          ? percentage(total.relay, sum)
                                          : 0
                                      }}%
                                      <i
                                        class="fa fa-arrow-up"
                                        style="color: #8031a7"
                                      ></i
                                    ></span>
                                  </div>
                                </div>
                                <div
                                  class="progress mt-1"
                                  data-height="8"
                                  style="height: 8px"
                                >
                                  <div
                                    class="progress-bar"
                                    role="progressbar"
                                    style="background: #8031a7"
                                    v-bind:style="{
                                      width: percentage(total.relay, sum) + '%',
                                    }"
                                    aria-valuenow="100"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> -->

                    <!--                                   
  <div class="row m-3">
    <div class="col-lg-3">
      <a href="#" class="data-card  p-3 ">
    <h3 class="my-2 ">270</h3>
    <h4 class="text-right">Care Facilities</h4>
    <p>Aenean lacinia bibendum nulla sed consectetur.</p>
    <span class="link-text text-right">
      View All Providers
      <svg width="25" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.8631 0.929124L24.2271 7.29308C24.6176 7.68361 24.6176 8.31677 24.2271 8.7073L17.8631 15.0713C17.4726 15.4618 16.8394 15.4618 16.4489 15.0713C16.0584 14.6807 16.0584 14.0476 16.4489 13.657L21.1058 9.00019H0.47998V7.00019H21.1058L16.4489 2.34334C16.0584 1.95281 16.0584 1.31965 16.4489 0.929124C16.8394 0.538599 17.4726 0.538599 17.8631 0.929124Z" fill="#753BBD"/>
</svg>
    </span>
  </a>
    </div>
      <div class="col-lg-3">
      <a href="#" class="data-card  p-3 ">
    <h3 class="my-2">270</h3>
    <h4 class="text-right">Care Facilities</h4>
    <p>Aenean lacinia bibendum nulla sed consectetur.</p>
    <span class="link-text text-right">
      View All Providers
      <svg width="25" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.8631 0.929124L24.2271 7.29308C24.6176 7.68361 24.6176 8.31677 24.2271 8.7073L17.8631 15.0713C17.4726 15.4618 16.8394 15.4618 16.4489 15.0713C16.0584 14.6807 16.0584 14.0476 16.4489 13.657L21.1058 9.00019H0.47998V7.00019H21.1058L16.4489 2.34334C16.0584 1.95281 16.0584 1.31965 16.4489 0.929124C16.8394 0.538599 17.4726 0.538599 17.8631 0.929124Z" fill="#753BBD"/>
</svg>
    </span>
  </a>
    </div>
     <div class="col-lg-3">
      <a href="#" class="data-card  p-3 ">
    <h3 class="my-2">270</h3>
    <h4 class="text-right">Care Facilities</h4>
    <p>Aenean lacinia bibendum nulla sed consectetur.</p>
    <span class="link-text text-right">
      View All Providers
      <svg width="25" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.8631 0.929124L24.2271 7.29308C24.6176 7.68361 24.6176 8.31677 24.2271 8.7073L17.8631 15.0713C17.4726 15.4618 16.8394 15.4618 16.4489 15.0713C16.0584 14.6807 16.0584 14.0476 16.4489 13.657L21.1058 9.00019H0.47998V7.00019H21.1058L16.4489 2.34334C16.0584 1.95281 16.0584 1.31965 16.4489 0.929124C16.8394 0.538599 17.4726 0.538599 17.8631 0.929124Z" fill="#753BBD"/>
</svg>
    </span>
  </a>
    </div>
     <div class="col-lg-3">
      <a href="#" class="data-card  p-3 ">
    <h3 class="my-2">270</h3>
    <h4 class="text-right">Care Facilities</h4>
    <p>Aenean lacinia bibendum nulla sed consectetur.</p>
    <span class="link-text text-right">
      View All Providers
      <svg width="25" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.8631 0.929124L24.2271 7.29308C24.6176 7.68361 24.6176 8.31677 24.2271 8.7073L17.8631 15.0713C17.4726 15.4618 16.8394 15.4618 16.4489 15.0713C16.0584 14.6807 16.0584 14.0476 16.4489 13.657L21.1058 9.00019H0.47998V7.00019H21.1058L16.4489 2.34334C16.0584 1.95281 16.0584 1.31965 16.4489 0.929124C16.8394 0.538599 17.4726 0.538599 17.8631 0.929124Z" fill="#753BBD"/>
</svg>
    </span>
  </a>
    </div>
    
          
     </div> -->
                    <!------------------>
                    <!-- <div class="row mr-3">
        <div class="col-lg-6">
      <a href="#" class="data-card  p-3 ">
    <h3 class="my-2">270</h3>
    <h4 class="text-right">Care Facilities</h4>
    <p>Aenean lacinia bibendum nulla sed consectetur.</p>
    <span class="link-text text-right">
      View All Providers
      <svg width="25" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.8631 0.929124L24.2271 7.29308C24.6176 7.68361 24.6176 8.31677 24.2271 8.7073L17.8631 15.0713C17.4726 15.4618 16.8394 15.4618 16.4489 15.0713C16.0584 14.6807 16.0584 14.0476 16.4489 13.657L21.1058 9.00019H0.47998V7.00019H21.1058L16.4489 2.34334C16.0584 1.95281 16.0584 1.31965 16.4489 0.929124C16.8394 0.538599 17.4726 0.538599 17.8631 0.929124Z" fill="#753BBD"/>
</svg>
    </span>
  </a>
    </div>
     <div class="col-lg-6">
      <a href="#" class="data-card  p-3 ">
    <h3 class="my-2">270</h3>
    <h4 class="text-right">Care Facilities</h4>
    <p>Aenean lacinia bibendum nulla sed consectetur.</p>
    <span class="link-text text-right">
      View All Providers
      <svg width="25" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.8631 0.929124L24.2271 7.29308C24.6176 7.68361 24.6176 8.31677 24.2271 8.7073L17.8631 15.0713C17.4726 15.4618 16.8394 15.4618 16.4489 15.0713C16.0584 14.6807 16.0584 14.0476 16.4489 13.657L21.1058 9.00019H0.47998V7.00019H21.1058L16.4489 2.34334C16.0584 1.95281 16.0584 1.31965 16.4489 0.929124C16.8394 0.538599 17.4726 0.538599 17.8631 0.929124Z" fill="#753BBD"/>
</svg>
    </span>
  </a>
    </div>
     </div> -->
                  </div>
                </div>
              </div>
            </div>
            <!--------->

            <!------->
            <div class="row  mx-2 mt-3 mb-0" style="width : 98%; height : 68%;">
             
              <div class="stretch-card  col-lg-7 p-0 ">
                <div class="card bg-transparent p-1 border border-0 ">
                  <div class="card-body p-0 m-0    w-100 ">
                    <div class="w-100 h-100">
                      <div class="d-flex  m-0 p-0">
  <div class="mr-auto p-2"><h5 class="justify-content-start text-secondary fw-bold">Courbes des colis : {{time2}}</h5></div>
  <div class="p-2"> 
   <!--  <dropdown-datepicker displayFormat='ymd'  /> -->
  <!--   <date-picker v-model="time2" type="year" @change="test" valueType="format" ></date-picker> -->
   <!--  <button @click ="v" >test</button> -->


  </div>
</div>
                     
                      <line-chart :entred_data="entred_data" :tl="numb" @changeMsg="setMessage" class="w-100 px-1 bg-white" style="height : 83%;"></line-chart>
                    </div>
                  </div>
                </div>
              </div>
              <!---------->
              <!--------->
              <div class="col-lg-1">

              </div>
        
          
               <div class="  p-0  col-lg-4 ">
                 <div class=" w-100">
                             <div class="w-100 mb-1">
                            <div class="card  l-bg-cherry h-100">
                              <a
                                class="card-statistic-3 shadow-sm px-5 py-3 data-card h-100"
                                
                              >
                               
                               
                                <div class="row align-items-center  d-flex">
                                  <div class="col-8 mt-4 ">
                                    <h2
                                      class="d-flex align-items-center mb-0 text-secondary"
                                    >
                                      {{ sum }}
                                    </h2>
                                      <h5 class="card-title mb-0">
                                    <i
                                      class="mdi mdi-package-variant text-secondary"
                                    ></i>
                                    Tous les colis
                                  </h5>
                                   <div class="mt-2">
                                
                                  <p>la totalité des colis</p>
                                </div>
                                  </div>
                                  
                                  <div class="col-4 text-right">
                                    <span>
                                                           <vue-circle
        :progress="100"
        :size="100"
        :reverse="false"
        line-cap="flat"
        :fill="fill"
        empty-fill="rgba(0, 0, 0, 0)"
        :animation-start-value="0.0"
        :start-angle="4.7"
        insert-mode="append"
        :thickness="5"
        :show-percent="false"
        @vue-circle-progress="0"
        @vue-circle-end="100">
          <span>100%</span>
      </vue-circle>
                                   </span>
                                  </div>
                                </div>
                               
                              </a>
         
                            </div>
                          </div>
                        </div>
                 <!------------------->

                  <div class=" w-100 my-3">
                             <div class="w-100 mb-1">
                            <div class="card  l-bg-cherry h-100">
                              <a
                                class="card-statistic-3 shadow-sm px-5 py-3 data-card h-100"
                                
                              >
                               
                               
                                <div class="row align-items-center  d-flex">
                                  <div class="col-8 mt-4 ">
                                    <h2
                                      class="d-flex align-items-center mb-0 text-warning"
                                    >
                                      {{total.encours}}
                                    </h2>
                                      <h5 class="card-title mb-0">
                                    <i
                                      class="mdi mdi-transfer text-warning" style="font-size : 20px;"
                                    ></i>
                                    Chez JIBLILI
                                  </h5>
                                   <div class="mt-2">
                                
                                  <p>Les colis pris en charge par Jiblili EXPRESS</p>
                                </div>
                                  </div>
                                  
                                  <div class="col-4 text-right">
                                    <span>
                                                           <vue-circle
        :progress="percentage(total.encours, sum)"
        :size="100"
        :reverse="false"
        line-cap="flat"
        :fill="fill2"
        empty-fill="rgba(0, 0, 0, 0.1)"
        :animation-start-value="0.0"
        :start-angle="4.7"
        insert-mode="append"
        :thickness="5"
        :show-percent="false"
        @vue-circle-progress="0"
        @vue-circle-end="100">
          <span>{{percentage(total.encours, sum)}}%</span>
      </vue-circle>
                                   </span>
                                  </div>
                                </div>
                               
                              </a>
         
                            </div>
                          </div>
                        </div>


                        <!-------------------------------->
                              <div class=" w-100 mb-3">
                             <div class="w-100 mb-1">
                            <div class="card  l-bg-cherry h-100">
                              <a
                                class="card-statistic-3 shadow-sm px-5 py-3 data-card h-100"
                                
                              >
                               
                               
                                <div class="row align-items-center  d-flex">
                                  <div class="col-8 mt-4 ">
                                    <h2
                                      class="d-flex align-items-center mb-0 " style="color : #8031a7;"
                                    >
                                      {{total.relay}}
                                    </h2>
                                      <h5 class="card-title mb-0">
                                    <i
                                      class="mdi mdi-map-marker"
                                      style="color: #8031a7"
                                    ></i>
                                    En point relais
                                  </h5>
                                   <div class="mt-2">
                                
                                  <p>Les colis disponibles aux points relais</p>
                                </div>
                                  </div>
                                  
                                  <div class="col-4 text-right">
                                    <span>
                                                           <vue-circle
        :progress="percentage(total.relay, sum)"
        :size="100"
        :reverse="false"
        line-cap="flat"
        :fill="fill3"
        empty-fill="rgba(0, 0, 0, 0.1)"
        :animation-start-value="0.0"
        :start-angle="4.7"
        insert-mode="append"
        :thickness="5"
        :show-percent="false"
        @vue-circle-progress="0"
        @vue-circle-end="100">
          <span>{{percentage(total.relay, sum)}}%</span>
      </vue-circle>
                                   </span>
                                  </div>
                                </div>
                               
                              </a>
         
                            </div>
                          </div>
                        </div>
           
            
                           
              </div>

              
          
             
            
              <!--------------------------------->
            
              <!--------------------------------->


              <!---------------------------------------->
             
              <!----------------------------------->


              
              <!----------------------------->


            </div>
          
<!--              <div class="row mx-2 mt-3 mb-0">
               <div class="stretch-card col-lg-12 p-0 pre-scrollable">
                <div class="card bg-transparent border border-0">
                  <div class="card-body">
                    <p class="card-title">
                      Classement des points relais de livraison
                    </p>

                    <div class="container">
                      <table class="table">
              

                        <tbody>
                          <tr
                            v-for="(item, index) in r"
                            :key="item.id"
                            v-bind:index="index"
                          >
                            <td>{{ item.relay.nom }}</td>
                            <td>{{ item.relay.ville.city }}</td>
                            <td class="text-right">
                              {{ percentage(item.count, resul) }} %
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

               
             </div> -->


           
          </div>

          <!-- content-wrapper ends -->
          <!-- partial:partials/_footer.html -->
          <Footer />
          <!-- partial -->
        </div>
        <!-- main-panel ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
  </section>
</template>

<script>
//import Menu from '@/components/Menu.vue';
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
import LineChart from "@/components/chart.vue";
//import DropdownDatepicker from 'vue-dropdown-datepicker';
 import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import moment from 'moment';
import VueCircle from 'vue2-circle-progress/src/index.vue'
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

 
// import axios from "axios";
// import Swal from "sweetalert2";

export default {
  components: {
    DatePicker,
    VueCircle,
    //Menu,
    Header,
    Footer,
    LineChart,
    PulseLoader,
    //DropdownDatepicker
    //Datepicker
  },
  data() {
    return {
      numb : "title",
       time1:  moment().format('YYYY-MM'),
        time2: moment().format('YYYY'),
        time3: null,
      selectedDate : "",
      entred_data:"",
      id: 1,
      from: "",
      to: "",
      fill : { gradient: ["#2f4353", "#2f4353", "#d2ccc4"] },
      fill2 : { gradient: [ "#fc575e ", "#fc575e" , "#f7b42c"] },
      fill3 : { gradient: ["#5f0a87", "#a4508b", "#a4508b"] },
      items: [],
      select: 7,
      count: {},
      compt: {},
      t: {},
      sum: 0,
      search: "",
      r: {},
      s: {},
      resul: 0,
      l: {},
      color:'#0275d8',
      size:'5px',
      somme: 0,
      arr1: ["Livre"],
      arr2: ["En cours de preparation", "En attente enlevement"],
      arr3: [
        "Disponible au point relais",
        "Reception retour client",
        "Non recupere",
        "Refusé par le client",
      ],
      arr4: ["Arrive au centre de tri", "Retourne au centre de tri"],
      arr5: [],
      arr6: [
        "Ramassage en cours",
        "En cours de distribution",
        "Livraison en cours",
        "Retour centre de tri en cours",
        "Retour expediteur en cours",
      ],
      arr7: ["Retourne"],
      error: "",
      showDate: false,
      chargement: false,
      statut: "tous",
      total: {},
    };
  },
  computed: {
    user() {
      return this.$store.getters.get_userAcc;
    },
  },
  created() {
    //
    this.countStatut();
    //this.changerDash(this.arr5, "tous");
  },

  mounted() {
    // this.$store.commit('set_loggedIn', false);
    ////var macaddress = require('macaddress')

    this.dash("relay_id", "relay");
    this.dash("client_id", "client");
   // this.livraison();
    //
  },

  methods: {
   
      
    v(){
      console.log("clicked")
      this.numb = "changed !!!!!";

    },
    changer() {
      console.log(this.select);
      this.countStatut();
      this.dash("relay_id", "relay");
      this.dash("client_id", "client");
     // this.livraison();
    },
    test(){
      this.error = "";
      console.log("testttttttttttt")
      if(this.time1) {
        this.countStatut();
      this.dash("relay_id", "relay");
      this.dash("client_id", "client");
      }
     // this.livraison();
      

    },
  setMessage() {
          this.numb = "yes changed";
        },


    countStatut() {
      this.t.select = this.select;
      this.t.from = this.from;
      this.t.to = this.to;
      this.t.m = this.time1;
      this.$store
        .dispatch("postItem", {
          //data to be sended
          nameRoute: "/api/statutSeller/" + this.user.id,
          t: this.t,
        })
        .then((res) => {
          // this.isLoading = false;
          console.log("verification statut ***********",res.data);
          this.verificationStatut(res.data);
        })
        .catch((err) => {
          // this.isLoading = false;
          if (!err.response)
            this.error = "Problème réseau, Impossible de joindre le serveur";
          else this.error = "Une erreur s'est produite lors de la récupération des statuts";
        });
    },
    livraison() {
      this.somme = 0;
      this.t.select = this.select;
      this.t.from = this.from;
      this.t.to = this.to;
      this.t.items = this.items;
      this.$store
        .dispatch("postItem", {
          //data to be sended
          nameRoute: "/api/livraison",
          t: this.t,
        })
        .then((res) => {
          // this.isLoading = false;
          console.log(res.data);
          this.somme = res.data.reduce((sum, obj) => sum + obj.count, 0);
          this.l = res.data;
          this.veriflivraison(this.somme);
        })
        .catch((err) => {
          // this.isLoading = false;
          if (!err.response)
            this.error = "Problème réseau, Impossible de joindre le serveur";
          else this.error = "Une erreur s'est produite ! ";
        });
    },
    veriflivraison(s) {
      this.compt.d = 0;
      this.compt.r = 0;
      var l = this.l;
      for (let i in l) {
        var type = l[i]["type_expedition"];
        if (type == "domicile")
          this.compt.d = this.percentage(l[i]["count"], s);
        if (type == "point relais")
          this.compt.r = this.percentage(l[i]["count"], s);
      }
      this.chargement = false;
    },
    dash(search, table) {
      this.chargement = true;
      this.t.m = this.time1;
    //  this.t.select = this.select;
      /* this.t.from = this.from;
      this.t.to = this.to; */
      this.t.items = this.items;
      this.t.search = search;
      this.t.table = table;
      this.t.id = this.user.id;
      this.$store
        .dispatch("postItem", {
          //data to be sended
          nameRoute: "/api/dashSeller/" + this.t.id,
          t: this.t,
        })
        .then((res) => {
          this.chargement = false;
          console.log(res.data);
          this.resul = res.data.reduce((sum, obj) => sum + obj.count, 0);
          if (search == "relay_id") this.r = res.data;
          if (search == "seller_id") this.s = res.data;
        })
        .catch((err) => {
          this.chargement = false;
          if (!err.response)
            this.error = "Problème réseau, Impossible de joindre le serveur";
          else this.error = "Une erreur s'est produite lors de la récupération des statuts";
        });
    },

    getItem(element, id) {
      var ville = "";
      this.$store
        .dispatch("getItem", {
          //data to be sended
          nameRoute: "/api/" + element + "/" + id,
        })
        .then((res) => {
          ville = res.data.ville.city;
          console.log(ville);
          return ville;
        });
    },
    verificationStatut(logs) {
      console.log("test");
      this.total.relay = 0;
      this.total.encours = 0;
      this.count.preparation = 0;
      this.total.warehouse = 0;
      this.count.warehouse_e = 0;
      this.count.warehouse_r = 0;
      this.total.retourne = 0;
      this.total.livre = 0;
      this.count.dispo = 0;
      this.count.non = 0;
      this.count.refus = 0;
      this.count.retourclient = 0;
      this.count.distr = 0;
      this.count.enlv = 0;
      this.count.livr = 0;
      this.count.ramassage = 0;
      this.count.retour1 = 0;
      this.count.retour2 = 0;
      this.total.perdu = 0;
      this.total.annule = 0;
      this.total.prep = 0;

      /* const reducer = (previousValue, currentValue) =>
        previousValue + currentValue; */
      for (let i in logs) {
        var statut = logs[i]["statut"];
        console.log(logs[i]["statut"]);
        if (statut == "Livre") this.total.livre = logs[i]["count"];
        if (statut == "Arrive au centre de tri")
          this.count.warehouse_e = logs[i]["count"];
        if (statut == "Retourne au centre de tri")
          this.count.warehouse_r = logs[i]["count"];
        if (statut == "Retourne") this.total.retourne = logs[i]["count"];
        if (statut == "Disponible au point relais")
          this.count.dispo = logs[i]["count"];
        if (statut == "Non recupere") this.count.non = logs[i]["count"];
        if (statut == "Reception retour client")
          this.count.retourclient = logs[i]["count"];
        if (statut == "Refuse par le client")
          this.count.refus = logs[i]["count"];
        if (statut == "En cours de preparation")
          this.count.preparation = logs[i]["count"];
        if (statut == "En cours de distribution")
          this.count.distr = logs[i]["count"];
        if (statut == "En attente enlevement")
          this.count.enlv = logs[i]["count"];
        if (statut == "Livraison en cours") this.count.livr = logs[i]["count"];
        if (statut == "Ramassage en cours")
          this.count.ramassage = logs[i]["count"];
        if (statut == "Retour centre de tri en cours")
          this.count.retour1 = logs[i]["count"];
        if (statut == "Retour expediteur en cours")
          this.count.retour2 = logs[i]["count"];
        if (statut == "Perdu") this.total.perdu = logs[i]["count"];
        //this.total.perdu =10;
        if (statut == "Annule") this.total.annule = logs[i]["count"];
      }
      // console.log(this.count)
      this.total.prep = this.count.preparation;
      this.total.enlv = this.count.enlv;
      this.total.relay = this.count.dispo + this.count.non + this.count.refus + this.count.retourclient;
      this.total.encours = this.count.distr + this.count.livr + this.count.ramassage + this.count.retour1 + this.count.retour2 +  this.count.warehouse_e + this.count.warehouse_r;
      this.sum = Object.values(this.total).reduce((a, b) => a + b, 0);
      console.log("total ******************", this.total);

      //pourcentages
    },

    percentage(partialValue, totalValue) {
      if (totalValue == 0) return 0;
      if(partialValue == 0) return 0;
      return ((100 * partialValue) / totalValue).toFixed(2);
    },

    changerDash(arr, statut) {
      this.chargement = true;
      if (arr) this.items = arr;
      this.dash("relay_id", "relay");
      this.dash("seller_id", "seller");
      this.livraison();
      this.statut = statut;
    },

    changerDate() {
      this.error = "";
      var to = this.to;
      var from = this.from;
      if (to) {
        if (!from) this.error = "Selectionnez une date de début";
        else if (from > to) {
          this.error =
            "Erreur !! La date de début est supérieure de la date de fin!";
        } else {
          this.countStatut();
          this.dash("relay_id", "relay");
          this.dash("client_id", "client");
          this.livraison();
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">

.home-section {
  position: relative;
  background: #e4e9f7;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}
.sidebar.open ~ .home-section {
  left: 250px;
  width: calc(100% - 250px);
}

.home-section .text {
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 0px;
}
/* serachbar */
input[type="date"]:before {
  content: attr(placeholder) !important;
  color: gray;
}
input[type="date"]:focus:before,
input[type="date"]:valid:before {
  content: "";
}

.input-search {
  border: white;
  background-color: transparent;
  font-family: ROBOTO;
  color: black;
  font-weight: normal;
  box-shadow: 0px 15px 10px -15px #111;
}

.input-search::placeholder {
  font-family: ROBOTO;
  color: black;
  font-weight: bolder;
  font-size: 14px;
}
input::placeholder {
  font-family: ROBOTO;
  font-style: normal;
  color: gray;
  font-size: 14px;
}
.input-search:-webkit-autofill:active {
  font-family: ROBOTO;
  color: darkslategray;
  font-weight: normal;
  font-size: 14px;
  background: darkslategray;
}
.pre-scrollable {
  max-height: 500px;
  overflow-y: scroll;
}

input[type="checkbox"]:checked {
  /*CSS after CHECKED*/
  color: #ffd369;
}

.dropdown-toggle:hover,
input[type="search"].input-search:hover,
.dropdown-toggle:active {
  background: #d7dbdc;
  font-weight: normal;
}
a {
  cursor: context-menu;
}
a:hover {
  color: rgb(255, 255, 255) !important;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.data-card {
  display: flex;
  flex-direction: column;
  // max-width: 20em;
  max-height: 15em;
  overflow: hidden;
  border-radius: 0.5em;
  text-decoration: none;
  background: white;
  transition: transform 0.45s ease, background 0.45s ease;

  h3 {
    color: #2e3c40;
    font-size: 2.2em;
    font-weight: 600;
    transition: color 0.45s ease, border 0.45s ease;
  }

  h4 {
    color: #627084;
    text-transform: uppercase;
    font-size: 1em;
    font-weight: 800;
    line-height: 1;
    letter-spacing: 0.1em;
    margin: 0 0 1.2em;
    transition: color 0.45s ease;
  }

  p {
    opacity: 0;
    color: #e4e9f7;
    font-weight: 600;
  }

  .link-text {
    display: block;
    color: #fbf8ff;
    font-size: 1.125em;
    font-weight: 600;
    line-height: 1.2;
    margin: 0 0;
    transition: color 0.45s ease;

    svg {
      margin-left: 0.5em;
      transition: transform 0.6s ease;
      color: #ffffff;

      path {
        transition: fill 0.45s ease;
        color: #ffffff;
      }
    }
  }

  &:hover {
    background: #2e3c40;
    transform: scale(1.02);

    h5 {
      color: #ffffff;
    }

    h4 {
      color: #ffffff;
    }

    p {
      opacity: 1;
      transform: none;
    }
    span {
      color: #ffffff;
    }

    .link-text {
      color: #ffffff;

      svg {
        animation: point 1.25s infinite alternate;

        path {
          fill: #ffffff;
        }
      }
    }
  }
}

@keyframes point {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0.125em);
  }
}
input[type="date"]:before {
  content: "" !important;
  color: transparent;
}
input[type="date"]:focus:before,
input[type="date"]:valid:before {
  content: "";
}
input {
  border: none;
  padding: 7%;
}
input::placeholder {
  font-family: ROBOTO;
  color: rgb(172, 37, 71);
  font-weight: bolder;
  font-size: 14px;
}
input:focus {
  border: none;
  box-shadow: inherit;
}

.box
{
  background:#fff;
  width:300px;
  height:400px;
  display:flex;
  justify-content:center;
  align-items:center;
  position:relative;
  flex-direction:column;
  box-shadow:0 30px 60px rgba(0,0,0,.4);
  transition: transform .2s;
}
/* .box:hover
{
 transform:scale(1.2);
} */
.box .percent
{
  width:150px;
  height:150px;
  position:relative;
}
.box .percent svg
{
  width:150px;
  height:150px;
  position:relative;
}
.box .percent svg circle
{
  width:150px;
  height:150px;
  fill:none;
  stroke-width:10;
  stroke:#000;
  transform:translate(5px,5px);
  stroke-dasharray:440;
  stroke-dashoffset:440;
  stroke-linecap:round;
}
.box .percent svg circle:nth-child(1)
{
  stroke-dashoffset:0;
  stroke:#f3f3f3;
}
.box .percent svg circle:nth-child(2)
{
  stroke-dashoffset:calc(440 - (440 * 87) / 100);
  stroke:#03a9f4;
}
.box .percent .num
{
  top:0;
  left:0;
  width:100%;
  height:100%;
  display:flex;
  justify-content:center;
  align-items:center;
  position:absolute;
  color:#111;
}
.box .percent .num h2
{
  font-size:48px;
}
.box .percent .num h2 span
{
  font-size:24px;
}
.box .text
{
  padding : 10px 0 0;
  color:#999;
  font-weight:700;
  letter-spacing:1px;
}
</style>
import { Script } from "vm";
