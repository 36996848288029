import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import VuexPersistence from "vuex-persist";
import router from "../router";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loggedIn: false,
    user: null,
    token: null,
    link: "http://127.0.0.1:8000",
    login_time: null,
    userAcc: null,
    menuItem: null,
  },
  plugins: [new VuexPersistence().plugin],
  mutations: {
    SET_user(state, payload) {
      state.user = payload;
    },
    set_ItemMenu(state, payload) {
      state.menuItem = payload;
    },
    set_userAcc(state, payload) {
      state.userAcc = payload;
    },
    SET_token(state, payload) {
      state.token = payload;
    },
    SET_loggedIn(state, payload) {
      state.loggedIn = payload;
    },
    set_link(state, payload) {
      state.link = payload;
    },
    set_loginTime(state, payload) {
      state.login_time = payload;
    },
  },
  actions: {
    performLoginAction({ commit, state }, payload) {
      commit("set_link", "http://bojibexp.jibliliexpress.ma/public");
      return new Promise((resole, reject) => {
        axios
          .post(state.link + payload.nameRoute, {
            email: payload.email,
            password: payload.password,
          })
          .then((res) => {
            commit("SET_token", res.data.access_token);
            commit("SET_user", res.data.user);
            commit("set_loginTime", res.data.user.last_cnx);
            commit("set_userAcc", res.data.details);

            // commit('SET_loggedIn',true)
            resole(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    performRegisterAction({ commit, state }, payload) {
      return new Promise((resole, reject) => {
        axios
          .post(state.link + payload.nameRoute, {
            name: payload.name,
            email: payload.email,
            password: payload.password,
          })
          .then((res) => {
            commit("SET_token", res.data.access_token);
            commit("SET_user", res.data.user);
            commit("SET_loggedIn", true);
            resole(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    performLogoutAction({ commit, state }, payload) {
      commit("SET_token", null);
      commit("SET_user", null);
      commit("set_ItemMenu", "dash");
      commit("SET_loggedIn", false);
      router.push("/");
      return new Promise((resole, reject) => {
        axios
          .post(state.link + payload.nameRoute, {
            token: state.token,
          })
          .then((res) => {
            resole(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getFile({ state}, playload){
      //promise to return res and err
      return new  Promise ((resolve, reject) => {
        axios({
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+state.token
        },
          url: state.link+ playload.nameRoute,
          method: playload.type,
          responseType: 'blob', // important
          data:{
            ids : playload.ids,
          }
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', playload.name+playload.ref+playload.ext);
          document.body.appendChild(link);
          link.click();
         
          
      })
        .then( res => {
          resolve(res);
        
        })
      
        .catch(err => {
          reject(err);
      
          
        })
  
      })

    },
    postFile({ state}, playload){
      return new  Promise ((resolve, reject) => {
      axios.create({
          headers: {
            'Content-Type': 'multipart/form-data',
            'crossDomain': true,
            'Access-Control-Allow-Origin': '*',
              'Authorization': 'Bearer '+state.token
          }
      })
      .post(state.link+ playload.nameRoute, playload.formdata, playload.config)
      .then( res => {
     
          resolve(res);

        })

        .catch(err => {
          reject(err);
 
          
        })
  
      })

    },
    deleteItem({ state}, playload){
      return new  Promise ((resolve, reject) => {
      axios.create({
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+state.token
          }
      })
      .delete(state.link+ playload.nameRoute)
      .then( res => {
     
          resolve(res);

        })

        .catch(err => {
          reject(err);
 
          
        })
  
      })

    },
    postItem({ state }, playload) {
      return new Promise((resolve, reject) => {
        axios
          .create({
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + state.token,
            },
          })
          .post(state.link + playload.nameRoute, playload.t)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getItem({ state }, playload) {
      //promise to return res and err
      return new Promise((resolve, reject) => {
        axios
          .create({
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + state.token,
            },
          })
          .get(state.link + playload.nameRoute)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    idItem({ state }, playload) {
      return new Promise((resolve, reject) => {
        axios
          .create({
            headers: {
              "Content-Type": "application/json",
              withCredentials: true,
              Authorization: "Bearer " + state.token,
            },
          })
          .post(state.link + playload.nameRoute)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getPdf({ state }, playload) {
      //promise to return res and err
      return new Promise((resolve, reject) => {
        axios({
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + state.token,
          },
          url: state.link + playload.nameRoute,
          method: "GET",
          responseType: "blob", // important
        })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "file" + playload.id + ".pdf");
            document.body.appendChild(link);
            link.click();
          })
          .then((res) => {
            resolve(res);
          })

          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  getters: {
    get_loggedIn(state) {
      return state.loggedIn;
    },
    get_user(state) {
      return state.user;
    },
    get_userAcc(state) {
      return state.userAcc;
    },
    get_loginTime(state) {
      return state.login_time;
    },
    get_menuItem(state) {
      return state.menuItem;
    },
  },
});
