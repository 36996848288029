<template>
  <section class="home-section">
    <div class="container-scroller">
      <!-- partial:partials/_navbar.html -->
      <Header title="Ajout colis" />
      <!-- partial -->
      <div class="container-fluid page-body-wrapper bg-light">
        <!-- partial:partials/_sidebar.html -->

        <!-- partial -->
        <div class="main-panel" style="width: 100%">
          <!---content wrapper start--->

          <div class="content-wrapper">
            <Shipping :id="parm" v-if="ship == true" />

            <div class="row mt-0" v-if="content">
              <div class="col-md-12 grid-margin">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-end flex-wrap">
                    <div class="mt-3" >
                      <p class=" d-flex justify-content-center align-self-center " ><i class="mdi mdi-cube icon-md mr-1"></i> 
                         <i class="mdi mdi-menu-right icon-sm mr-1 "></i>
                         <span class=" py-2 " >Nouveau colis</span>
                       </p>
                    </div>
                                            <!--     <button @click="notification">tester notification </button> -->

                  </div>
                </div>
              </div>
            </div>
             <div class="row" style="margin-top: 1%">
                        <div class="col-12">
                          <div
                            v-if="error"
                            class="alert alert-danger px-3 shadow-sm border border-white"
                            role="alert"
                          >
                          <i
                        class="mdi mdi-close-circle-outline pl-1"
                        style="color: darkred; font-size:20px;"
                      ></i>
                           {{error}}
                          </div>
                        </div>
                      </div>
                <div class="">
               <p 
                v-if="chargement"
                class="text-primary justify-content-center  flex-row d-flex font-weight-bold"
              >
                 <pulse-loader
                      class="ml-2"
                      :loading="true"
                      :color="color"
                      :size="size"
                    ></pulse-loader>
              </p>
            </div>

            <div class="row">
              <div class="row"></div>
              <form
                class="forms-sample"
                @submit.prevent="handlesubmit"
                style="width: 100%"
              >
                <div class="col-12 grid-margin stretch-card">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title">Expédition :</h4>
                      <div class="row">
                        <div class="col-12">
                          <div class="form-group row" style="margin-left: 4%" @click="test">
                            <label
                              for="exampleInputUsername2"
                              class="col-lg-2 col-form-label"
                              >Client :
                            </label>
                            <div class="col-lg-7">
                              <model-select :options="options"
                                :isError="!err"
                                v-model="item"
                                placeholder=""
                                >
                             </model-select>
         <!-- <button @click="test">test</button> -->

         <!-- string value -->
         <!-- <model-select :options="options2"
                                 v-model="item2"
                                 placeholder="select item2"
                                 required>
         </model-select>        
          -->
            <!--    <select
                                class="form-control form-select form-select-md mb-3"
                                aria-label="Default select example"
                                v-model="clientSelected"
                                required
                              >
                                <option
                                  v-for="item in clients"
                                  :value="item.id"
                                  :key="item.id"
                                >
                                  {{ item.nom }}
                                </option>
                              </select> -->
                            </div>
                           <div class="col-lg-3">
                              <a href="/addcustomer" class="link-success" >Nouveau client</a>
                           </div>
                          </div>
                        </div>
                      </div>
                      <!----------------->
                      <div class="row">
                        <div class="col-12">
                          <div class="form-group row" style="margin-left: 4%">
                            <label
                              for="exampleInputUsername2"
                              class="col-lg-2 col-form-label"
                              >Livraison :
                            </label>
                            <div class="col-lg-6">
                              <div class="form-group">
                                <select
                                  required
                                  class="form-control form-select form-select-md mb-3"
                                  aria-label="Default select example"
                                  v-model="shipment.type_expedition"
                                >
                                  <option value="domicile">Domicile</option>
                                  <option value="point relais" >
                                    Point relais
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="form-group row" style="margin-left: 4%"  @click="test">
                            <label
                              for="exampleInputUsername2"
                              class="col-lg-2 col-form-label"
                              >Point relais :
                            </label>
                            <div class="col-lg-7">
                              <model-select :options="options2"
                                v-model="item2"
                                placeholder=""
                                :isError="!err2"
                                >
                             </model-select>
                              
                              <!-- <select
                                class="form-control form-select form-select-md mb-3"
                                aria-label="Default select example"
                                v-model="relaySelected"
                                required
                              >
                                <option
                                  v-for="item in relais"
                                  :value="item.id"
                                  :key="item.id"
                                >
                                  {{ item.nom }}
                                </option>
                              </select> -->
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12">
                          <div class="form-group row" style="margin-left: 4%">
                            <label
                              for="exampleInputUsername2"
                              class="col-lg-2 col-form-label"
                              >Email de reception :
                            </label>
                            <div class="col-lg-6">
                              <div class="form-group">
                                <input
                                  type="email"
                                  class="form-control"
                                  id="exampleInputEmail2"
                                  placeholder="mail@mail.com "
                                  v-model="shipment.mail_reception"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!---------------->
                    </div>
                  </div>
                </div>
                <!-------------------------------->

                <div class="col-12 grid-margin stretch-card">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title">Stockage :</h4>
                      <div class="row">
                        <div class="col-12">
                          <div class="form-group row" style="margin-left: 4%">
                            <label
                              for="exampleInputUsername2"
                              class="col-lg-2 col-form-label"
                              >Assurance :
                            </label>
                            <div class="col-lg-8">
                              <div class="form-group">
                                <select
                                  required
                                  class="form-control form-select form-select-md mb-3"
                                  aria-label="Default select example"
                                  v-model="shipment.assurance"
                                >
                                  <option value="forfitaire">Forfitaire</option>
                                  <option value="adv">Ad valorem</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12">
                          <div class="form-group row" style="margin-left: 4%">
                            <label
                              for="exampleInputUsername2"
                              class="col-lg-2 col-form-label"
                              >Centre de tri :
                            </label>
                            <div class="col-lg-8">
                              <div class="form-group">
                                <select
                                  class="form-control form-select form-select-md mb-3"
                                  aria-label="Default select example"
                                  v-model="depot_id"
                                  required
                                >
                                  <option
                                    v-for="item in depots"
                                    :value="item.id"
                                    :key="item.id"
                                  >
                                    {{ item.nom }} -- {{ item.ville.city }}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!---------------->
                    </div>
                  </div>
                </div>
                <!---------------------------->
                <div class="col-12 grid-margin stretch-card">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title">Paiement :</h4>

                      <!----------------->

                      <div class="row">
                        <div class="col-12">
                          <div class="form-group row" style="margin-left: 4%">
                            <label
                              for="exampleInputUsername2"
                              class="col-lg-2 col-form-label"
                              >Valeur déclarée :
                            </label>
                            <div class="col-lg-6">
                              <div class="form-group">
                                <input
                                  type="number"
                                  class="form-control"
                                  id="exampleInputEmail2"
                                  placeholder="Montant payé en Dirhams "
                                  required
                                  step="O.01"
                                  v-model="shipment.valeur"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12">
                          <div class="form-group row" style="margin-left: 4%">
                            <label
                              for="exampleInputUsername2"
                              class="col-lg-2 col-form-label"
                              >COD :
                            </label>
                            <div class="col-lg-6">
                              <div class="form-group">
                                <input
                                  type="number"
                                  class="form-control"
                                  id="exampleInputEmail2"
                                  placeholder="Montant restant en Dirhams "
                                  required
                                  step="0.01"
                                  v-model="shipment.cod"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

      <!--                 <div class="row">
                        <div class="col-12">
                          <div class="form-group row" style="margin-left: 4%">
                            <label
                              for="exampleInputUsername2"
                              class="col-lg-2 col-form-label"
                              >Montant de retour :
                            </label>
                            <div class="col-lg-8">
                              <div class="form-group">
                                <input
                                  type="number"
                                  class="form-control"
                                  id="exampleInputEmail2"
                                  placeholder="Montant à payer en cas de retour "
                                  required
                                  step="0.01"
                                  v-model="shipment.montant_retour"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> -->
                      <div class="row">
                        <div class="col-12">
                          <div class="form-group row" style="margin-left: 4%">
                            <label
                              for="exampleInputUsername2"
                              class="col-lg-2 col-form-label"
                              >Poids :
                            </label>
                            <div class="col-lg-6">
                              <div class="form-group">
                                <input
                                  type="number"
                                  class="form-control"
                                  id="exampleInputEmail2"
                                  placeholder="En kilogramme "
                                  required
                                  step="0.01"
                                  v-model="shipment.poids"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-3"></div>
                        <div class="col-lg-3">
                          <button v-if="!isLoading"
                            type="submit"
                            class="btn btn-success mr-2"
                            style="width: 100%"
                          >
                            Valider
                                                      </button>

                             <div class="text-center py-1" v-else>
                            <pulse-loader
                      class="ml-2"
                      :loading="true"
                      :color="color1"
                      :size="size1"
                    ></pulse-loader>
                          </div>
                        </div>
                        <div class="col-lg-3">
                           <button class="btn btn-secondary " style="width : 100%;" @click="back">Annuler</button>
                        </div>
                      </div>
                      <div class="row" style="margin-top: 1%">
                        <div class="col-12">
                          <div
                            v-if="msg"
                            class="alert alert-danger px-3 shadow-sm border border-white"
                            role="alert"
                          >
                          <i
                        class="mdi icon-sm mdi-close-circle-outline pr-1 "
                        style="color: darkred; font-size : 20px;"
                      ></i>
                            Veullez renseigner les champs obligatoires
                          </div>
                        </div>
                      </div>
                      <div class="row" style="margin-top: 1%">
                        <div class="col-12">
                         <!--  <div
                            v-if="msg"
                            class="alert alert-danger text-center"
                            role="alert"
                          >
                            {{ msg }}
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>




           <div v-if="modalPdf">
    <transition name="model">
     <div class="modal-mask">
      <div class="modal-wrapper">
       <div class="modal-dialog bg-transparent modal-dialog-centered modal-lg" >
         <div class="modal-content bg-light " style="">
            <div class="modal-header bg-light">
                  <h5 class="modal-title w-100 text-center text-secondary"> Télchargement du bon de commande </h5>
                                  <button
                              type="button"
                              class="border-0 bg-transparent"
                              @click="closeModal()"
                            >
                              <i class="mdi mdi-close text-secondary fw-bold"></i>
                            </button>
              </div>
    
         <div class=" bg-light p-0">
         
          <div class="modal-body row  m-0 w-100 container d-flex justify-content-center">
        <p class="text-dark">Vous pouvez télécharger le bon de commande diréctement via le bouton "Téléchargr" ou plus tard via l'interface "Colis"</p>
      </div>
      <div class="modal-footer">
         <button type="button" class="btn btn-primary mr-2 "  @click="pdf()" :disabled="isLoading">
                                             <span v-if="!isLoading" >Télcharger maintenant</span>
                          <span v-else >Chargement en cours</span>
                        </button>
        <button type="button" class="btn btn-secondary" @click="closeModal()">Plus tard</button>
      </div>
          


<!-- Upload  -->







           
      
          
         </div>
        </div>
       </div>
      </div>
     </div>
    </transition>
   </div>


          <Footer />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
//import Shipping from '@/components/Shipping.vue';
//import axios from 'axios';
import Swal from "sweetalert2";
 import { ModelSelect } from 'vue-search-select'
 import 'vue-search-select/dist/VueSearchSelect.css'
 import PulseLoader from "vue-spinner/src/PulseLoader.vue";
//import Loading from 'vue3-loading-overlay';
//import $ from 'jquery';
//import { notify } from "@kyvg/vue3-notification";

// import Dropdown from 'vue-simple-search-dropdown';

export default {
  components: {
    Header,
    Footer,
    PulseLoader,
    //Shipping,
    //Loading,
     ModelSelect
  },

  data() {
    return {
      options: [],
      item: {},
      options2: [],
      item2: {},
      me: "nouheyla",
      parm: "",
      password: "",
      err:true,
      err2:true,
      error: '',
      clients: null,
      relais: null,
      vendeurs: null,
      depots: null,
      valeur: "",
      cod: "",
      poids: "",
      assurance: "",
      taxe: "",
      montant_retour: "",
      date_enlevement_demandee: "",
      search: "",
      nom: "",
      prenom: "",
      type_expedition: "",
      date_exp_demandee: "",
      modalPdf:false,
      client_id: "",
      relay_id: "",
      relay_nom: "",
      seller_id: "",
      seller_nom: "",
      color:'#0275d8',
      size:'5px',
      color1:'#5cb85c',
      size1:'10px',
      depot_id: "",
      varclient: false,
      varrelais: false,
      varvendeur: false,
      msg: false,
      ship: false,
      content: true,
      shipment: {},
      chargement: true,
      isLoading: false,
      fields: { text: "nom", value: "id" },
      clientSelected: "",
      relaySelected: "",
      sellerSelected: "",
      auth: {},
      id:'',
      ref:'',
    };
  },

  mounted() {
    
    this.auth = this.$store.getters.get_user;
    console.log("auth", this.auth);
    console.log("auth id", this.auth["userable_id"]);
    this.afficherClients();
    this.afficherRelays();
    // $('#test').selectpicker();
    this.$store
      .dispatch("getItem", {
        //data to be sended
        nameRoute: "/api/getwarehouses",
      })
      .then((res) => {
        this.chargement = false
        console.log(res.data);
        this.depots = res.data;
      })
      .catch((err) => {
        console.log(err);
        this.chargement = false;
         if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur"
         else
        this.error = "Une erreur s'est produite lors du chargement des centres de tri ! ";
      });
  },

  methods: {
    test(){
      this.msg = false;
      this.err = true;
      this.err2 = true;
      console.log("clicked!!!")
    },
    closeModal(){
        this.modalPdf = false;

      },
    handlesubmit() {
      console.log(this.item.value)
      this.msg = false;
      this.error = "";
      this.err = true;
      this.err2 = true;
      if(!this.item.value )  {this.err = false; this.msg = true;}
      else if (!this.item2.value )  {this.err2 = false; this.msg = true;}
      else
      {Swal.fire({
        title: "Attention!",
        text: "Etes vous sur de vouloir ajouter ce colis?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "green",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
          this.add();
        }
      });}
    },
     pdf(){
       this.isLoading = true;
    console.log("pdf");
       this.$store.dispatch('getFile', {//data to be sended
        nameRoute : '/api/generate-pdf/'+this.id,
        ref:this.ref,
        type:"GET",
        name:"BC ",
        ext:".pdf"

      })
      .then( res => {  
        console.log(res);
        this.isLoading = false;    
        this.closeModal();
        this.notification('le bon de commande a été généré avec succés');
        this.$router.push("/colis");
        
       
       })
      .catch(err => {
        this.isLoading=false;
        console.log(err);
        this.notificationError("Une erreur s'est produite lors de la génération du bon de commande");
      })  
  
  },
    afficherClients() {
      this.error = "";
      this.msg=false;
      console.log("clients")
      this.$store
        .dispatch("getItem", {
          //data to be sended
          nameRoute: "/api/getClientsbyvendeur/" + this.auth["userable_id"],
        })
        .then((res) => {
          this.chargement = false;
          console.log("success")
          this.fillArr(res.data)
          this.count = res.data.length;
        })
        .catch((err) => {
          console.log("err",err);
          this.chargement = false;
           if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur"
           else
          this.error = "Une erreur s'est produite lors du chargement des clients! ";
        });
    },
    fillArr(res){
      console.log("for")
         for (let i in res) {
           let client = {} ;
           client.value = res[i].id;
           client.text = res[i].mobile + " -- "+ res[i].nom;
         console.log("*************", client)

           this.options.push(client);}
          console.log("*************", this.options)

    },
     back(){
    // $('#myform').trigger("reset");
    //document.getElementsByClassName("forms-sample").reset();
    this.$router.go(-1);
  },
    afficherRelays() {
      this.error = "";
      this.msg=false;
      this.$store
        .dispatch("getItem", {
          //data to be sended
          nameRoute: "/api/getrelaisactived",
        })
        .then((res) => {
          this.chargement = false;
          this.fillArr2(res.data)
          this.count = res.data.length;
        })
        .catch((err) => {
          console.log(err);
          this.chargement = false;
           if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur"
           else
          this.error = "Une erreur s'est produite lors du chargement des points relais ! ";
        });
    },
    fillArr2(res){
      console.log("for")
         for (let i in res) {
           let relay = {} ;
           relay.value = res[i].id;
           relay.text =  res[i].nom;
         console.log("*************", relay)

           this.options2.push(relay);}
          console.log("*************", this.options2)

    },
  
    add() {
      
      
      this.shipment.client_id = this.item.value;
      this.shipment.relay_id = this.item2.value;
      this.shipment.seller_id = this.auth["userable_id"];
      this.shipment.warehouse_id = this.depot_id;
      console.log("FORMULAIRE : " + this.shipment.client_id);
      console.log("FORMULAIRE : " + this.shipment.relay_id);
      console.log("FORMULAIRE : " + this.shipment.seller_id);
      console.log("FORMULAIRE : " + this.shipment.warehouse_id);
      console.log("auth id", this.auth["userable_id"]);
      this.isLoading = true;
      this.$store
        .dispatch("postItem", {
          //data to be sended
          nameRoute: "/api/addcolis",
          t: this.shipment,
        })
        .then((res) => {
          this.isLoading = false;
          this.notification("Colis ajouté avec succés")
          console.log(res.data.id);
          console.log("reussi");
          this.id = res.data.id;
          this.ref = res.data.ref;
          this.openModal();
        })
        .catch((err) => {
          this.notificationError("Un problème est survenu lors de l'ajout du colis")
          console.log(err);
          this.isLoading = false;
          this.error =
            "Une erreur s'est produite lors de l'ajout de ce colis ! ";
        });
    
    },
    openModal(){
      this.modalPdf = true;

    },
    
    notification(msg){
      this.$notify({
  group : "foo",
  type: 'success',
  title: 'DONE',
  text: msg
});
    },
    notificationError(msg){
      this.$notify({
  group : "foo",
  type: 'error',
  title: 'Error',
  text: msg
});
    },
    

    addClient() {
      this.$router.push("/addcustomer");
    },
  },
};
</script>
<style scoped>
@import url(https://cdn.syncfusion.com/ej2/material.css);

input[type="search"]:focus {
  outline: inherit;
  box-shadow: inherit;
  border: none;
}
.pointer {
  cursor: pointer;
}

.home-section {
  position: relative;
  background: #e4e9f7;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}
.sidebar.open ~ .home-section {
  left: 250px;
  width: calc(100% - 250px);
}
.home-section .text {
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 0px;
}
i {
  color: #6c757d;
  font-size: 30px;
}
.btn-light:hover {
  background: rgb(200, 228, 206);
}

/* a:hover {
  color: rgb(93, 0, 0) !important;
  font-weight: bolder;
    font-weight: inherit;

}
table tr:hover{
color : darkred;
box-shadow:darkred 1.95px 1.95px 2.6px;
font-weight: bolder;
} */
.dropdown-item:hover {
  background: none;
}
input[list] {
  margin-left: auto;
  margin-right: auto;

  max-width: 500px;
  background: #d2e9ff;
  padding: 20px 20px 20px 20px;
  font: 12px Arial, Helvetica, sans-serif;
  color: #666;
}
::selection {
  color: #fff;
  background: #664aff;
}
.wrapper {
  max-width: 450px;
  margin: 150px auto;
}
.wrapper .search-input {
  background: #fff;
  width: 100%;
  border-radius: 5px;
  position: relative;
  box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
}
/* .search-input input{
  height: 55px;
  width: 100%;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 0 60px 0 20px;
  font-size: 18px;
  box-shadow: 0px 1px 5px rgba(0,0,0,0.1);
}
.search-input.active input{
  border-radius: 5px 5px 0 0;
} */
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-content{
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}
</style>
import { Script } from "vm";
